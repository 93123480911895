import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TextLink from "../../../../../components/textlink"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/nachtgesaenge" />
      }
    >
      <Seo title="Stimmungen im Gedicht" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Stimmungen im Gedicht
            </Heading>
          </Stack>
          <Paragraph>
            Während die in der 1. Strophe beschriebene Szene durchweg positiv,
            ja beinahe schon überfüllt von den Verlockungen und Reizen des
            Lebens erscheint, folgt in der 2. Strophe eine große Verunsicherung
            und Skepsis darüber, was die Zukunft wohl bringen mag.
          </Paragraph>
          <Paragraph>
            Für den Literaturwissenschaftler Winfried Menninghaus spricht das
            ganze Gedicht aus der Leerzeile in der Mitte heraus. Denn der Titel,
            ›Hälfte des Lebens‹, kann sich entweder auf eine Lebenshälfte
            beziehen, oder aber auf das Dazwischen, die Mitte zwischen zwei
            Lebenshälften.
          </Paragraph>
          <Paragraph>
            Auffällig ist, so Menninghaus, dass das lyrische Ich erst mit Beginn
            der 2. Strophe zu Wort kommt – und zwar mit dem Ausruf »Weh mir«. In
            den Bildern der 1. Strophe, die auf die erste Lebenshälfte Bezug
            nehmen, ist es schon nicht mehr präsent. Sie sind lediglich
            Erinnerungen. Auf die bevorstehende Lebenshälfte blickt das Ich
            wiederum mit Sorge voraus. Es befindet sich also genau in der
            Lebensmitte: zwischen Nicht-mehr und Noch-nicht.
          </Paragraph>
          <Paragraph>
            Übrigens: In den Gedichten, die Hölderlin im Turm verfasst, kommt
            das Wort »Ich« nicht mehr vor.
          </Paragraph>
          <Paragraph>
            Mehr von Winfried Menninghaus über das Gedicht ›Hälfte des Lebens‹:
            <br />
            <TextLink href="https://www.suhrkamp.de/buecher/haelfte_des_lebens-winfried_menninghaus_41717.html">
              Winfried Menninghaus: Hälfte des Lebens. Versuch über Hölderlins
              Poetik
            </TextLink>
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
